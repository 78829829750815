import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
} from "@material-ui/core";
import ShortURLForm from "./form";
import { yupResolver } from "@hookform/resolvers/yup";
import firebase from "firebase/compat/app";
import { getIPAddress } from "../../hooks/useIPAddress";
import { useSnackbar } from "notistack";
import { ShortURLSchema } from "../../utils/schemas";
import { useSetDocument } from "../../firestore/hooks";
import { ShortURL } from "../../firestore/models/ShortURL";

type Props = {
  open: boolean;
  category?: string[] | string;
  onClose: () => void;
  onSave?: () => void;
};

const ShortURLNew = (props: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    //@ts-ignore
    resolver: yupResolver(ShortURLSchema),
  });
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const createShortURL = useSetDocument<ShortURL>("shorturl");

  useEffect(() => {
    if (ip === null) {
      getIPAddress().then((ip) => setIp(ip));
    }
  }, [ip]);

  const handleSave = (values: any) => {
    setLoading(true);

    if (typeof values.category === "string") {
      values.category = values.category
        .split(",")
        .map((val: string) => val.trim());
    }
    if (typeof values.alias === "string") {
      values.alias = values.alias.split(",").map((val: string) => val.trim());
    }

    const resource = {
      ...values,
      clicks: 0,
      ip: ip ?? "",
      createdAt: firebase.firestore.Timestamp.now(),
    } as Partial<ShortURL>;

    createShortURL(resource, resource.keyword)
      .then(() => {
        enqueueSnackbar("ShortURL successfully saved.", { variant: "success" });
        props.onSave && props.onSave();
        props.onClose();
      })
      .catch((err) => enqueueSnackbar(`Error: ${err}`, { variant: "error" }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={props.open}>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        disableBackdropClick={true}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>New Short URL</DialogTitle>
        <DialogContent>
          <ShortURLForm
            shorturl={
              props.category
                ? ({ category: props.category } as ShortURL)
                : undefined
            }
            errors={errors}
            register={register}
            onSubmit={handleSubmit(handleSave)}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={props.onClose}>
            cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={handleSubmit(handleSave)}
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default ShortURLNew;
