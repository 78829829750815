import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { shorten, shortenM } from "../../utils/funcs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useTheme from "@material-ui/core/styles/useTheme";
import Confirm from "../../components/Confirm";
import ShortURLNew from "./new";
import ShortURLEdit from "./edit";
import { useSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
// import { useCollection } from "../../firestore/hooks";
import { ShortURL } from "../../firestore/models";
import useDeleteDocument from "../../firestore/hooks/useDeleteDocument";
import { OrderByOptions, WhereOptions } from "react-redux-firebase";
import PageContainer from "../../components/PageContainer";
import { ICON_STYLE } from "../../utils/constants";
import CustInput from "../../components/Input";
import { IDProp } from "../../firestore/types";
import { DB } from "../../firebase";

const ShorturlList = () => {
  const [newShortURL, setNewShortURL] = useState<boolean>(false);
  const [editShortURL, setEditShortURL] = useState<ShortURL | undefined>(
    undefined
  );
  const { enqueueSnackbar } = useSnackbar();
  const [timeoutID, setTimeoutID] = useState<NodeJS.Timeout | undefined>();
  // const [limit, setLimit] = useState(10000);
  const [shortURLs, setShortURLs] = useState<
    Array<ShortURL & IDProp & { isAlias: boolean; mainKeyword: string }>
  >([]);
  const [search, setSearch] = useState<Record<string, string>>({
    keyword: "",
    title: "",
    url: "",
    category: "",
  });
  const [searchString, setSearchString] = useState<string>("");
  const onSearchStringChanged = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchString(event.currentTarget.value);
  };
  const [searchedShortUrls, setSearchedShortUrls] = useState<
    Array<ShortURL & IDProp & { isAlias: boolean; mainKeyword: string }>
  >([]);

  const [filter, setFilter] = useState<{
    order: OrderByOptions;
    where?: Record<string, WhereOptions> | undefined;
  }>({
    order: ["keyword", "asc"],
  });

  useEffect(() => {
    DB()
      .collection("shorturl")
      .orderBy("keyword", "asc")
      .get()
      .then((docs) => {
        const shortArray: Array<
          ShortURL & IDProp & { isAlias: boolean; mainKeyword: string }
        > = [];
        docs.forEach((doc) => {
          shortArray.push({
            keyword: doc.data().keyword,
            mainKeyword: doc.data().keyword,
            id: doc.id,
            category: doc.data().category,
            title: doc.data().title,
            url: doc.data().url,
            createdAt: doc.data().createdAt,
            ip: doc.data().ip,
            clicks: doc.data().clicks,
            isAlias: false,
          });
          const aliases: Array<string> = doc.data()?.alias ?? [];
          aliases.map((alias) => {
            shortArray.push({
              keyword: alias,
              mainKeyword: doc.data().keyword,
              id: doc.id,
              category: doc.data().category,
              title: doc.data().title,
              url: doc.data().url,
              createdAt: doc.data().createdAt,
              ip: doc.data().ip,
              clicks: doc.data().clicks,
              isAlias: true,
            });
          });
        });
        setShortURLs(shortArray);
      });
  }, []);

  // const shortURLs = useCollection<ShortURL>(
  //   "shorturl",
  //   filter.where && Object.keys(filter.where).length
  //     ? Object.values(filter.where)
  //     : undefined,
  //   filter.where &&
  //     Object.keys(filter.where).length &&
  //     !filter.where[filter.order[0]]
  //     ? undefined
  //     : filter.order,
  //   limit
  // );
  const deleteShortURL = useDeleteDocument("shorturl");

  const handleDelete = (id: string) => {
    deleteShortURL(id)
      .then(() =>
        enqueueSnackbar("Short URL successfully deleted!", {
          variant: "success",
        })
      )
      .catch((err) => enqueueSnackbar(`${err}`, { variant: "error" }));
  };

  const handleCategoryClick = (cat: string) => {
    setSearch((prev) => ({ ...prev, category: cat }));
    setFilter((prev) => ({
      ...prev,
      where: {
        category: ["category", "array-contains", cat],
      },
    }));
  };

  // const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (timeoutID) {
  //     clearTimeout(timeoutID);
  //   }
  //   setSearch((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  //   updateSearch(e.target.name, e.target.value);
  // };

  const updateSearch = useCallback(
    (name: string, value: string) =>
      setTimeoutID(
        setTimeout(
          () =>
            setFilter((prev) => {
              const newFilter = {
                ...prev,
                where: {
                  [name]: [
                    name,
                    name === "category" ? "array-contains" : ">=",
                    value,
                  ] as WhereOptions,
                },
              };
              if (
                newFilter.where.category &&
                newFilter.where.category[2].trim() === ""
              ) {
                delete newFilter.where.category;
              }
              return newFilter;
            }),
          1000
        )
      ),
    [setTimeoutID]
  );

  // const toggleSort = useCallback(
  //   (field: string) => () => {
  //     setFilter((prev) => ({
  //       ...prev,
  //       order: [field, prev.order && prev.order[1] === "asc" ? "desc" : "asc"],
  //     }));
  //   },
  //   [setFilter]
  // );

  // const favTags = [
  //   "checkout",
  //   "extension",
  //   "plugin",
  //   "intern",
  //   "help",
  //   "support",
  //   "doku",
  //   "partner",
  //   "download",
  //   "webstollen",
  //   "kampagne",
  // ].sort();

  useEffect(() => {
    if (searchString.length > 1) {
      const getData = setTimeout(() => {
        const searchedShortURLList = shortURLs
          .filter((v) => {
            return (
              v.id.toLowerCase().includes(searchString.toLowerCase()) ||
              v.url.toLowerCase().includes(searchString.toLowerCase()) ||
              v.title.toLowerCase().includes(searchString.toLowerCase()) ||
              (v.keyword &&
                v.keyword.toLowerCase().includes(searchString.toLowerCase())) ||
              (v.category &&
                v.category
                  .toString()
                  .toLowerCase()
                  .includes(searchString.toLowerCase()))
            );
          })
          .map((v) => {
            let score = 0.1;
            const sea = searchString.toLowerCase();
            if (
              v.category &&
              v.category.toString().toLowerCase().includes(sea)
            ) {
              score = score + 20;
              if (v.category.indexOf(sea) === 0) {
                score = score * 1;
              }
            }
            if (v.id.toLowerCase().includes(sea)) {
              score = score + 100;
              if (v.id.indexOf(sea) === 0) {
                score = score * 2;
              }
            }
            if (v.title && v.title.toLowerCase().includes(sea)) {
              score = score + 60;
              if (v.title.indexOf(sea) === 0) {
                score = score * 1.5;
              }
            }
            if (v.url && v.url.toLowerCase().includes(sea)) {
              score = score + 50;
              if (v.url.indexOf(sea) === 0) {
                score = score * 1.5;
              }
            }
            if (v.keyword && v.keyword.toLowerCase().includes(sea)) {
              score = score + 100;
              if (v.id.indexOf(sea) === 0) {
                score = score * 2;
              }
            }
            return { ...v, searchScore: score };
          })
          .sort((a, b) => {
            if (a.searchScore > b.searchScore) {
              return -1;
            } else if (a.searchScore < b.searchScore) {
              return 1;
            } else {
              return 0;
            }
          });
        // if (searchedPlugins.length === 1) {
        //   navigate(`${searchedPlugins[0].pluginId}`);
        // }
        setSearchedShortUrls(searchedShortURLList);
      }, 200);
      return () => clearTimeout(getData);
    } else {
      setSearchedShortUrls(Object.values(shortURLs || {}));
    }
  }, [searchString, shortURLs]);

  const SearchBar = () => {
    return (
      <CustInput
        key={`SearchBar`}
        type="text"
        autoFocus
        placeholder="URLs durchsuchen"
        value={searchString}
        onChange={onSearchStringChanged}
        style={{ padding: 10, fontSize: 28, outline: "none" }}
      />
    );
  };

  return (
    <PageContainer
      title={"Short URLs"}
      loading={!shortURLs}
      onAdd={() => setNewShortURL(true)}
    >
      {/* <div className="flex justify-between">
        {favTags.map((t) => (
          <Chip onClick={() => handleCategoryClick(t)} label={t} />
        ))}
      </div> */}
      <SearchBar />
      <TableContainer>
        <Table width={"100%"}>
          {/* <TableHead>
            <TableRow>
              <TableCell>
                Keyword
                <div className="float-right">
                  {filter.order[0] === "keyword" ? (
                    <FontAwesomeIcon
                      onClick={toggleSort("keyword")}
                      icon={
                        filter.order[1] === "desc"
                          ? [ICON_STYLE, "sort-alpha-down"]
                          : [ICON_STYLE, "sort-alpha-up"]
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={toggleSort("keyword")}
                      icon={[ICON_STYLE, "sort"]}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell>
                Title
                <div className="float-right">
                  {filter.order[0] === "title" ? (
                    <FontAwesomeIcon
                      onClick={toggleSort("title")}
                      icon={
                        filter.order[1] === "desc"
                          ? [ICON_STYLE, "sort-alpha-down"]
                          : [ICON_STYLE, "sort-alpha-up"]
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={toggleSort("title")}
                      icon={[ICON_STYLE, "sort"]}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell>
                URL
                <div className="float-right">
                  {filter.order[0] === "url" ? (
                    <FontAwesomeIcon
                      onClick={toggleSort("url")}
                      icon={
                        filter.order[1] === "desc"
                          ? [ICON_STYLE, "sort-alpha-down"]
                          : [ICON_STYLE, "sort-alpha-up"]
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={toggleSort("url")}
                      icon={[ICON_STYLE, "sort"]}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell className="w-1/12">
                Clicks
                <div className="float-right">
                  {filter.order[0] === "clicks" ? (
                    <FontAwesomeIcon
                      onClick={toggleSort("clicks")}
                      icon={
                        filter.order[1] === "desc"
                          ? [ICON_STYLE, "sort-alpha-down"]
                          : [ICON_STYLE, "sort-alpha-up"]
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      onClick={toggleSort("clicks")}
                      icon={[ICON_STYLE, "sort"]}
                    />
                  )}
                </div>
              </TableCell>
              <TableCell>Category</TableCell>
              <TableCell>&nbsp;</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  onChange={handleSearchChange}
                  name={"keyword"}
                  placeholder={"search..."}
                  type={"search"}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={search.title}
                  onChange={handleSearchChange}
                  name={"title"}
                  placeholder={"search..."}
                  type={"search"}
                  fullWidth
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={search.url}
                  onChange={handleSearchChange}
                  name={"url"}
                  placeholder={"search..."}
                  type={"search"}
                  fullWidth
                />
              </TableCell>
              <TableCell />
              <TableCell>
                <TextField
                  value={search.category}
                  onChange={handleSearchChange}
                  name={"category"}
                  placeholder={"search..."}
                  type={"search"}
                  fullWidth
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead> */}
          <TableBody>
            {/* {shortURLs ? (
              Object.keys(shortURLs).map((id) => (
                <ShorturlListRow
                  key={shortURLs[id].keyword}
                  onCategoryClick={handleCategoryClick}
                  onDelete={handleDelete}
                  onEdit={(id) => setEditShortURL(shortURLs[id])}
                  data={shortURLs[id]}
                  id={id}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align={"center"}>
                  No Data.
                </TableCell>
              </TableRow>
            )} */}
            {searchedShortUrls.length > 0 ? (
              searchedShortUrls
                .filter((v, index) => {
                  return index < 20;
                })
                .map((id, index) => (
                  <ShorturlListRow
                    key={searchedShortUrls[index].keyword}
                    onCategoryClick={handleCategoryClick}
                    onDelete={handleDelete}
                    onEdit={(id) => setEditShortURL(searchedShortUrls[index])}
                    data={searchedShortUrls[index]}
                    id={`${index}_${id.id}`}
                  />
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align={"center"}>
                  No Data.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {searchedShortUrls.length < 20 ? (
          <div className="text-center my-5">
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "lighter",
                color: "white",
                margin: 15,
                marginTop: 20,
                textAlign: "center",
              }}
            >
              {`Die Liste enthält alle gefundenen Ergebnisse`}
            </Typography>
          </div>
        ) : (
          <div className="text-center my-5">
            <Typography
              style={{
                fontSize: 14,
                fontWeight: "lighter",
                color: "white",
                margin: 15,
                marginTop: 20,
                textAlign: "center",
              }}
            >
              {`Weitere Suchergebnisse mit geringer Relevanz wurden ausgeblendet`}
            </Typography>
          </div>
        )}
        <div className="text-right my-5" style={{ width: `100%` }}>
          <Typography
            style={{
              fontSize: 14,
              fontWeight: "lighter",
              color: `#9eddff`,
              margin: 15,
              marginTop: 20,
              textAlign: "right",
            }}
          >
            {`* Aliasse einer anderen Short-URL`}
          </Typography>
        </div>
        {/* <div className="text-center my-5">
          <Button
            variant={"contained"}
            onClick={() => setLimit((limit) => limit + 15)}
          >
            more
          </Button>
        </div> */}
      </TableContainer>

      {newShortURL && (
        <ShortURLNew open={newShortURL} onClose={() => setNewShortURL(false)} />
      )}

      {editShortURL && (
        <ShortURLEdit
          open={!!editShortURL}
          shorturl={editShortURL}
          onClose={() => setEditShortURL(undefined)}
        />
      )}
    </PageContainer>
  );
};

export default ShorturlList;

type ShorturlListRowProps = {
  data: ShortURL & { isAlias: boolean; mainKeyword: string };
  id: string;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onCategoryClick: (category: string) => void;
};

const ShorturlListRow = ({
  data,
  onEdit,
  onDelete,
  id,
  onCategoryClick,
}: ShorturlListRowProps) => {
  const theme = useTheme();
  return (
    <tr>
      <td>
        <Link
          style={data.isAlias === true ? { color: `#9eddff` } : undefined}
          component={RouterLink}
          to={`/shorturl/${data.mainKeyword}`}
        >
          {`${data.keyword}${data.isAlias === true ? `*` : ``}`}
        </Link>{" "}
        {data.locked && (
          <FontAwesomeIcon
            style={{ marginLeft: "5px" }}
            color={"grey"}
            size={"xs"}
            icon={[ICON_STYLE, "lock"]}
          />
        )}
      </td>
      <td>
        <span title={data.title}>{shorten(data.title)}</span>
      </td>
      <td>
        <Link href={data.url} target="_blank" title={data.url}>
          {shortenM(data.url)}
        </Link>
      </td>
      <td align={"right"}>
        <code>{data.clicks}</code>
      </td>
      <td>
        {typeof data.category === "string" ? (
          data.category ? (
            <Chip
              onClick={() => onCategoryClick(data.category as string)}
              label={data.category}
            />
          ) : null
        ) : (
          data.category.map((cat) =>
            cat ? (
              <Chip onClick={() => onCategoryClick(cat)} label={cat} />
            ) : null
          )
        )}
      </td>
      <td>
        <ButtonGroup variant="text">
          <Button
            className={"c2c"}
            data-clipboard-text={`https://ws-url.de/${data.keyword}`}
          >
            <FontAwesomeIcon
              icon={[ICON_STYLE, "copy"]}
              color={theme.palette.success.main}
            />
          </Button>
          <Button component="a" href={data.url} target={"_blank"}>
            <FontAwesomeIcon
              color={theme.palette.info.main}
              icon={[ICON_STYLE, "external-link"]}
            />
          </Button>

          {data.locked !== true && data.isAlias === false && (
            <>
              <Button onClick={() => onEdit(id)}>
                <FontAwesomeIcon
                  icon={[ICON_STYLE, "edit"]}
                  color={theme.palette.info.main}
                />
              </Button>

              <Confirm
                title={
                  <>
                    Delete ShortURL: <code>{data.keyword}</code>
                  </>
                }
                onConfirm={() => onDelete(data.keyword)}
                content={
                  <>
                    Do you really want to delete the Short URL "
                    <code>{data.keyword}</code>" for the domain{" "}
                    <Typography display={"inline"} noWrap>
                      <code>{shortenM(data.url)}</code>
                    </Typography>{" "}
                    ?
                  </>
                }
              >
                {(confirm) => (
                  <Button onClick={() => confirm(true)}>
                    <FontAwesomeIcon
                      color={theme.palette.error.main}
                      icon={[ICON_STYLE, "trash"]}
                    />
                  </Button>
                )}
              </Confirm>
            </>
          )}
        </ButtonGroup>
      </td>
    </tr>
  );
};
