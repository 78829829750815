import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
} from "@material-ui/core";
import ShortURLForm from "./form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { getIPAddress } from "../../hooks/useIPAddress";
import { ShortURLSchema } from "../../utils/schemas";
import { useSetDocument } from "../../firestore/hooks";
import { ShortURL } from "../../firestore/models/ShortURL";

type Props = {
  open: boolean;
  shorturl?: ShortURL;
  onClose: () => void;
  onSave?: () => void;
};

const ShortURLEdit = (props: Props) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    //@ts-ignore
    resolver: yupResolver(ShortURLSchema),
  });
  const [loading, setLoading] = useState(false);
  const [ip, setIp] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const updateShortURL = useSetDocument<ShortURL>("shorturl");

  useEffect(() => {
    getIPAddress().then((ip) => setIp(ip));
  }, []);

  const handleSave = (values: Record<string, any>) => {
    setLoading(true);

    if (!values.category) {
      values.category = [];
    }
    if (typeof values.category === "string") {
      values.category = values.category.split(",").map((val) => val.trim());
    }
    if (!values.alias) {
      values.alias = [];
    }
    if (typeof values.alias === "string") {
      values.alias = values.alias.split(",").map((val) => val.trim());
    }
    const resource = { ...values, ip: ip } as ShortURL;
    updateShortURL(resource, resource.keyword)
      .then(() => {
        enqueueSnackbar("Successfully saved!", { variant: "success" });
        props.onSave && props.onSave();
        props.onClose();
      })
      .catch((err) => enqueueSnackbar(`${err}`, { variant: "error" }))
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={props.open}>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        disableBackdropClick={true}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Edit Short URL</DialogTitle>
        <DialogContent>
          <ShortURLForm
            shorturl={props.shorturl}
            errors={errors}
            register={register}
            onSubmit={handleSubmit(handleSave)}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={props.onClose}>
            cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={handleSubmit(handleSave)}
          >
            save
          </Button>
        </DialogActions>
      </Dialog>
    </Modal>
  );
};

export default ShortURLEdit;
