import { Card, CardContent, Chip, Grid, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import React, { useState, useMemo } from "react";
import numbro from "numbro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../../utils/constants";

interface DataRecord {
  createdAt: string;
  total: number;
  [key: string]: any;
}

type AggregationType = "sum" | "average" | "profit" | "churn";

interface MetricsResult {
  aggregatedValue: number;
  amount: number;
  diff: number;
  percentage: number;
  trend: "increase" | "decrease";
  rawData: number[];
}

const getDaysBetween = (startDate: Date, endDate: Date): number => {
  return Math.max(
    1,
    Math.floor(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    )
  );
};

const TrendCard = ({
  title,
  data,
  startEndDates,
  aggregationType,
  secondaryData,
}: {
  title: string;
  data: Array<Record<string, any>>;
  startEndDates: Date[];
  aggregationType: AggregationType;
  secondaryData?: Array<Record<string, any>>;
}) => {
  const [selectedType, selectType] = useState<string | null>("total");

  const aggregateData = useMemo(() => {
    const filterDataByDates = (
      dataset: Array<Record<string, any>> | null
    ): Array<Record<string, any>> | null => {
      if (!dataset) {
        return null;
      }
      return dataset.filter((v) => {
        const createdAtDate = new Date(v.createdAt);
        return (
          createdAtDate >= startEndDates[0] && createdAtDate <= startEndDates[1]
        );
      });
    };

    const filteredData = filterDataByDates(data);

    const filteredSecondaryData = filterDataByDates(secondaryData ?? null);

    if (secondaryData) {
      const filteredSecondaryData = secondaryData.filter((v) => {
        const createdAtDate = new Date(v.createdAt);
        return (
          createdAtDate >= startEndDates[0] && createdAtDate <= startEndDates[1]
        );
      });
    }

    if (!filteredData) {
      return {
        aggregatedValue: 0,
        amount: 0,
        diff: 0,
        percentage: 0,
        trend: "increase" as const,
        rawData: [],
      };
    }

    if (filteredData.length === 0) {
      return {
        aggregatedValue: 0,
        amount: 0,
        diff: 0,
        percentage: 0,
        trend: "increase" as const,
        rawData: [],
      };
    }

    const dataMappedAll = filteredData.map(
      (v) => v[selectedType === null ? "total" : selectedType] as number
    );

    let churnCount = 0;

    const amountOfDays = getDaysBetween(startEndDates[0], startEndDates[1]);

    const calculateAggregatedValue = (): number => {
      switch (aggregationType) {
        case "average": {
          const sum = dataMappedAll.reduce(
            (total, current) => total + current,
            0
          );
          const avg = sum / dataMappedAll.length;
          return +avg.toFixed(2);
        }
        case "profit": {
          if (!filteredSecondaryData) {
            return 0;
          }

          const businessValues = filteredSecondaryData.map(
            (v) => v["business"] || 0
          );
          const proValues = filteredSecondaryData.map((v) => v["pro"] || 0);

          const businessAvg =
            businessValues.reduce((sum, val) => sum + val, 0) /
            businessValues.length;
          const proAvg =
            proValues.reduce((sum, val) => sum + val, 0) / proValues.length;

          const businessProfit =
            (businessAvg / amountOfDays) *
            49 *
            amountOfDays *
            (amountOfDays / 30);
          const proProfit =
            (proAvg / amountOfDays) * 99 * amountOfDays * (amountOfDays / 30);

          if (selectedType === "business") return +businessProfit.toFixed(2);
          if (selectedType === "pro") return +proProfit.toFixed(2);
          return +(businessProfit + proProfit).toFixed(2);
        }
        case "churn": {
          const sum = dataMappedAll.reduce(
            (total, current) => total + current,
            0
          );
          const avg = sum / dataMappedAll.length;

          churnCount =
            filteredSecondaryData?.reduce((count, event) => {
              if (selectedType === "business")
                return event.type === "abo_canceled" &&
                  event.aboType === "abocloud Business"
                  ? count + 1
                  : count;
              if (selectedType === "pro")
                return event.type === "abo_canceled" &&
                  event.aboType === "abocloud Pro"
                  ? count + 1
                  : count;
              return event.type === "abo_canceled" ? count + 1 : count;
            }, 0) ?? 0;

          return churnCount;
        }
        default: {
          return +dataMappedAll.reduce((total, current) => total + current, 0);
        }
      }
    };

    const aggregatedValue = calculateAggregatedValue() ?? [];

    let diff = 0;
    let percentage = 0;

    const firstValue = dataMappedAll[0];
    const lastValue = dataMappedAll[dataMappedAll.length - 1];

    if (aggregationType == "churn") {
      diff = churnCount * -1;
      const sum = dataMappedAll.reduce((total, current) => total + current, 0);
      percentage = (churnCount / (sum / dataMappedAll.length)) * 100;
    } else {
      diff = lastValue - firstValue;
      percentage = firstValue !== 0 ? Math.abs((diff / firstValue) * 100) : 0;
    }

    return {
      aggregatedValue,
      amount: dataMappedAll[dataMappedAll.length - 1],
      diff,
      percentage,
      trend: diff >= 0 ? "increase" : "decrease",
      rawData: dataMappedAll,
    };
  }, [data, startEndDates, selectedType, aggregationType]);

  const handleClick = (type: string) => {
    selectType(selectedType === type ? null : type);
  };

  const getGradientColor = (type: string | null) => {
    switch (type) {
      case "free":
        return "#306d3d";
      case "business":
        return "#3071bd";
      case "pro":
        return "#6f42b6";
      default:
        return "#242526";
    }
  };

  return (
    <Card
      style={{
        width: 400,
        marginLeft: 30,
        marginRight: 30,
        marginBottom: 50,
        borderRadius: 15,
        background: `linear-gradient(to right bottom, ${getGradientColor(
          selectedType
        )}, #444445)`,
      }}
    >
      <CardContent style={{ flexDirection: "column", margin: 15 }}>
        <Typography
          style={{ fontSize: 18, fontWeight: "light", marginBottom: 10 }}
        >
          {title}
        </Typography>
        <div className="flex justify-between">
          {title === "Profit" || title === "Cancelations" ? (
            <>
              <Chip
                variant={selectedType === "total" ? "default" : "outlined"}
                size="small"
                label="total"
                onClick={() => handleClick("total")}
              />
              <Chip
                variant={selectedType === "business" ? "default" : "outlined"}
                size="small"
                label="business"
                onClick={() => handleClick("business")}
              />
              <Chip
                variant={selectedType === "pro" ? "default" : "outlined"}
                size="small"
                label="pro"
                onClick={() => handleClick("pro")}
              />
            </>
          ) : title === "Reports" ? (
            <>
              <Chip
                variant={selectedType === "daily" ? "default" : "outlined"}
                size="small"
                label="daily"
                onClick={() => handleClick("daily")}
              />
              <Chip
                variant={selectedType === "weekly" ? "default" : "outlined"}
                size="small"
                label="weekly"
                onClick={() => handleClick("weekly")}
              />
              <Chip
                variant={selectedType === "monthly" ? "default" : "outlined"}
                size="small"
                label="monthly"
                onClick={() => handleClick("monthly")}
              />
              <Chip
                variant={selectedType === "quarterly" ? "default" : "outlined"}
                size="small"
                label="quarterly"
                onClick={() => handleClick("quarterly")}
              />
            </>
          ) : (
            <>
              <Chip
                variant={selectedType === "total" ? "default" : "outlined"}
                size="medium"
                label="total"
                onClick={() => handleClick("total")}
              />
              <Chip
                variant={selectedType === "free" ? "default" : "outlined"}
                size="medium"
                label="free"
                onClick={() => handleClick("free")}
              />
              <Chip
                variant={selectedType === "business" ? "default" : "outlined"}
                size="medium"
                label="business"
                onClick={() => handleClick("business")}
              />
              <Chip
                variant={selectedType === "pro" ? "default" : "outlined"}
                size="medium"
                label="pro"
                onClick={() => handleClick("pro")}
              />
            </>
          )}
        </div>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={7}>
            <Typography style={{ fontSize: 28, fontWeight: "bold" }}>
              {title !== "Profit"
                ? aggregateData.aggregatedValue
                : aggregateData.aggregatedValue + "€"}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Chart
              options={{
                legend: { show: false },
                grid: { show: false },
                tooltip: { enabled: false },
                chart: {
                  type: "line",
                  toolbar: { show: false },
                  zoom: { enabled: false },
                  width: "100%",
                },
                stroke: { curve: "smooth", width: 2.5 },
                states: { hover: { filter: { type: "none" } } },
                xaxis: {
                  labels: { show: false },
                  axisBorder: { show: false },
                  axisTicks: { show: false },
                  crosshairs: { show: false },
                },
                yaxis: {
                  labels: { show: false },
                  axisBorder: { show: false },
                  axisTicks: { show: false },
                  crosshairs: { show: false },
                },
              }}
              series={[
                {
                  name: "Value",
                  data: title !== "Cancelations" ? aggregateData.rawData : [],
                },
              ]}
            />
          </Grid>
        </Grid>
        {title === "Customer Average" && (
          <div className="flex justify-start items-center">
            <FontAwesomeIcon
              icon={[
                ICON_STYLE,
                aggregateData.diff > 0
                  ? "arrow-up"
                  : aggregateData.diff < 0
                  ? "arrow-down"
                  : "arrow-right",
              ]}
              color={
                aggregateData.diff > 0
                  ? "green"
                  : aggregateData.diff < 0
                  ? "red"
                  : "white"
              }
              style={{
                width: 20,
                height: 20,
                borderRadius: 50,
                padding: 5,
                backgroundColor: "rgb(255, 255, 255,0.1)",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            />
            <Typography
              style={{ marginLeft: 5, fontSize: 15, fontWeight: "normal" }}
            >
              {`${aggregateData.diff > 0 ? "" : "-"}${numbro(
                aggregateData.percentage
              ).format({
                mantissa: 2,
              })}%`}
            </Typography>
            <Typography
              style={{ marginLeft: 5, fontSize: 14, fontWeight: "lighter" }}
            >
              {aggregateData.diff > 0
                ? `more ${title}`
                : aggregateData.diff < 0
                ? `less ${title}`
                : `no new ${title}`}
            </Typography>
          </div>
        )}
        {title === "Cancelations" && (
          <div className="flex justify-start items-center">
            <FontAwesomeIcon
              icon={[
                ICON_STYLE,
                aggregateData.diff > 0
                  ? "arrow-up"
                  : aggregateData.diff < 0
                  ? "arrow-down"
                  : "arrow-right",
              ]}
              color={
                aggregateData.diff > 0
                  ? "green"
                  : aggregateData.diff < 0
                  ? "red"
                  : "white"
              }
              style={{
                width: 20,
                height: 20,
                borderRadius: 50,
                padding: 5,
                backgroundColor: "rgb(255, 255, 255,0.1)",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
              }}
            />
            <Typography
              style={{ marginLeft: 5, fontSize: 15, fontWeight: "normal" }}
            >
              {`${numbro(aggregateData.percentage).format({
                mantissa: 2,
              })}%`}
            </Typography>
            <Typography
              style={{ marginLeft: 5, fontSize: 14, fontWeight: "lighter" }}
            >
              {" churn rate"}
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default TrendCard;
