import { Card, Grid, Typography } from "@material-ui/core";
import React from "react";
import { COMPONENTS } from "../../constants/colors";
import PluginCheckoutV2 from "./components/plugin/Checkouts";
import PluginSalesType from "./components/plugin/Sales/SalesType";
import PluginSalesAmount from "./components/plugin/Sales/SalesAmount";
import AbscentEmployeesV2 from "./components/webstollen/absences";
import "./index.css";
import MarketingTMBannerV2 from "./components/marketing/tmBanner";
import MarketingShortUrlsV2 from "./components/marketing/shortUrls";
import PluginUpdatesV2 from "./components/plugin/Updates";
import DashbarUserV2 from "./components/dashbar/User";
import DashbarCompaniesV2 from "./components/dashbar/Companies";
import TicketsV2 from "./components/webstollen/ticktes";
import useProfile from "../../hooks/firestore/useProfile";
import MarketingBannerV2 from "./components/marketing/banner";
import OldDashboardV2 from "./components/deprecated";
import DashbarSalesVolumeV2 from "./components/dashbar/Stats";
import CheckoutTimelineV2 from "./components/plugin/CheckoutTimeline";
import AbocloudUserV2 from "./components/abocloud";

const DashboardV2 = () => {
  const profile = useProfile();
  return (
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor: COMPONENTS.DASHBOARD.BACKGROUND,
        padding: 40,
        paddingBottom: 70,
      }}
    >
      <div
        className="dashboardWrapper"
        style={
          profile?.lastname?.toLowerCase() === "stepanek"
            ? { width: "100%", minWidth: "100%" }
            : {}
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography
            style={{
              color: "#FFF",
              fontSize: 32,
              fontFamily: "Roboto",
              fontWeight: `500`,
            }}
          >
            {`Dashboard`}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Typography
            style={{
              color: "#FFF",
              fontSize: 18,
              fontFamily: "Roboto",
              fontWeight: `300`,
            }}
          >
            {`Hallo${
              profile?.firstname ? ` ${profile?.firstname}` : ""
            }, hier sind die neusten Kennzahlen für dich!`}
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <PluginCheckoutV2 type="day" />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <PluginCheckoutV2 type="month" />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <PluginSalesType />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <PluginSalesAmount />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <AbscentEmployeesV2 />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <CheckoutTimelineV2 type="prod" />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <CheckoutTimelineV2 type="test" />
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <PluginUpdatesV2 />
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <DashbarUserV2 />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <DashbarCompaniesV2 />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <AbocloudUserV2/>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TicketsV2 />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={7}>
            <MarketingTMBannerV2 />
          </Grid>
          <Grid item xs={12} md={12} lg={5}>
            <MarketingShortUrlsV2 />
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <MarketingBannerV2 />
          </Grid>
          <Grid item xs={12} md={12} lg={5}>
            <DashbarSalesVolumeV2 />
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <OldDashboardV2 />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DashboardV2;
