import { useCollection } from "../../../../firestore/hooks";
import {
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Confirm from "../../../../components/Confirm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { COLL_SHORTURL, functions } from "../../../../firebase";
import { useSnackbar } from "notistack";
import { ShortURL } from "../../../../firestore/models/ShortURL";
import { ICON_STYLE } from "../../../../utils/constants";

type DuplicatesProps = {
  shorturl: ShortURL;
};

const Duplicates = ({ shorturl }: DuplicatesProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const duplicates = useCollection<ShortURL>(COLL_SHORTURL, [
    ["keyword", "!=", shorturl.keyword],
    ["url", "==", shorturl.url],
  ]);

  const makeAlias = (alias: string, keyword: string) => {
    window.alert(`Funktion derzeit nicht verfügbar [${keyword} | ${alias}]`);
    // functions()
    //   .httpsCallable("resource")({
    //     resource: "shorturl",
    //     action: "addAlias",
    //     data: {
    //       id: keyword,
    //       alias: alias,
    //       force: true,
    //     },
    //   })
    //   .then((res) => {
    //     if (res.data === false) {
    //       return enqueueSnackbar("Error: Something went wrong", {
    //         variant: "error",
    //       });
    //     }
    //     if (
    //       res.data.status &&
    //       res.data.status === "error" &&
    //       res.data.message
    //     ) {
    //       return enqueueSnackbar("Error: " + res.data.message, {
    //         variant: "error",
    //       });
    //     }
    //     if (alias === shorturl?.keyword) {
    //       navigate("/shorturl/" + keyword);
    //     } else {
    //       // loadData();
    //       //loadDuplicates();
    //     }
    //     return enqueueSnackbar(
    //       `Successfully saved alias '${alias}' to keyword '${keyword}'.`,
    //       { variant: "success" }
    //     );
    //   })
    //   .catch((err) => enqueueSnackbar("Error: " + err, { variant: "error" }));
  };

  return duplicates && Object.keys(duplicates).length ? (
    <Grid xs={12}>
      <Typography variant={"h4"}>Duplicates</Typography>
      <List dense>
        {Object.keys(duplicates).map((i) => (
          <ListItem key={i}>
            <ListItemText
              primary={
                <Link
                  component={RouterLink}
                  to={`/shorturl/${duplicates[i].keyword}`}
                >
                  {duplicates[i].keyword}
                </Link>
              }
            />
            <ListItemSecondaryAction>
              {!shorturl?.locked && (
                <Confirm
                  title={`Make this '${shorturl.keyword}' an alias of '${duplicates[i].keyword}'`}
                  onConfirm={() =>
                    makeAlias(shorturl?.keyword, duplicates[i].keyword)
                  }
                  content={
                    <>
                      Do you want to add '{shorturl.keyword}' as an alias to
                      this ({duplicates[i].keyword})? This Short URL '
                      {shorturl.keyword}' will be deleted.
                    </>
                  }
                >
                  {(confirm) => (
                    <IconButton onClick={() => confirm(true)}>
                      <FontAwesomeIcon icon={[ICON_STYLE, "inbox-out"]} />
                    </IconButton>
                  )}
                </Confirm>
              )}

              {shorturl?.keyword && (
                <Confirm
                  title={`Make '${duplicates[i].keyword}' an alias of this '${shorturl.keyword}'`}
                  onConfirm={() =>
                    makeAlias(duplicates[i].keyword, shorturl.keyword)
                  }
                  content={
                    <>
                      Do you want to add '{duplicates[i].keyword}' as an alias
                      to this ({shorturl.keyword})? Short URL '
                      {duplicates[i].keyword}' will be deleted.
                    </>
                  }
                >
                  {(confirm) => (
                    <IconButton onClick={() => confirm(true)}>
                      <FontAwesomeIcon icon={[ICON_STYLE, "inbox-in"]} />
                    </IconButton>
                  )}
                </Confirm>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Grid>
  ) : null;
};

export default Duplicates;
