import React, { useCallback, useState } from "react";
import { useCollection } from "../../../../../firestore/hooks";
import firebase from "firebase/compat/app";
import { WhereT } from "../../../../../types";
import {
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import {
  LicenceChip,
  LicenceTypChip,
  SubscriptionChip,
} from "./components/Chips";

type Props = {
  pluginId: string;
};

const InstallView = ({ pluginId }: Props) => {
  const [where, setWhere] = useState<WhereT>();
  const [graph, setGraph] = useState(false);
  const installs = useCollection<Record<string, any>>(
    `plugin/${pluginId}/install`,
    where,
    where ? undefined : ["last", "desc"],
    1000
  );

  const handleSearch = useCallback(
    (key: string, value: string) => {
      if (value) {
        setWhere([
          key === "id" ? firebase.firestore.FieldPath.documentId() : key,
          ">=",
          value,
        ]);
      } else {
        setWhere(undefined);
      }
    },
    [setWhere]
  );

  const reducedData = installs
    ? Object.entries(installs)
        .filter(([, v]) => {
          return v?.version !== undefined && v?.version !== "undefined";
        })
        .map(([, v]): string => {
          return v.version;
        })
        .reduce<Record<string, number>>((prev, curr) => {
          if (prev?.[curr]) {
            return { ...prev, [curr]: prev[curr] + 1 };
          } else {
            return { ...prev, [curr]: 1 };
          }
        }, {})
    : [];
  const orderedData = Object.entries(reducedData)
    .sort((a, b) => {
      if (a[0] < b[0]) {
        return -1;
      } else if (a[0] > b[0]) {
        return 1;
      } else {
        return 0;
      }
    })
    .reduce((acc, curr) => {
      const key = curr[0];
      const value = curr[1];
      return { ...acc, [key]: value };
    }, {});

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Plugin Versions",
      },
    },
  };
  const data = {
    labels: Object.keys(orderedData),
    datasets: [
      {
        label: "Versions",
        data: Object.entries(orderedData).map(([, v]) => {
          return v;
        }),
        backgroundColor: `rgba(52, 137, 235)`,
      },
    ],
  };
  return (
    <div className="mt-6">
      <FormControlLabel
        label={"Visualize Versions"}
        control={
          <Switch checked={graph} onChange={() => setGraph((prev) => !prev)} />
        }
      />
      {graph ? (
        <div className="m-auto w-1/2">
          <Bar data={data} options={options} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{ backgroundColor: "#333", marginTop: 20 }}
        >
          <Table size="small" aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography
                    style={{
                      color: "#EEE",
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                  >
                    {`Domain`}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      color: "#EEE",
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                  >
                    {`Plugin-Version`}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    style={{
                      color: "#EEE",
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                  >
                    {`Lizenz`}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    style={{
                      color: "#EEE",
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                  >
                    {`Subscription`}
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    style={{
                      color: "#EEE",
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                  >
                    {`Lizenztyp`}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      color: "#EEE",
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                  >
                    {`Shop-Version`}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      color: "#EEE",
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                  >
                    {`php-Version`}
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography
                    style={{
                      color: "#EEE",
                      fontSize: 14,
                      fontFamily: "Roboto",
                      fontWeight: "700",
                    }}
                  >
                    {`Aktualisiert`}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(installs ?? {}).map((install, index) => (
                <React.Fragment>
                  <TableRow>
                    <TableCell align="left">
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 12,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {install?.domain ?? `n/a`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 12,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {install?.version ?? `n/a`}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <LicenceChip data={install} />
                    </TableCell>
                    <TableCell align="center">
                      <SubscriptionChip data={install} />
                    </TableCell>
                    {/* <TableCell align="right">
                      {row.status === 0 ? (
                        <Tooltip title={"Eine Lizenz wurde angefragt"}>
                          <FontAwesomeIcon
                            size="1x"
                            color={GREY[700]}
                            icon={[ICON_STYLE, "circle-question"]}
                          />
                        </Tooltip>
                      ) : row.status === 1 ? (
                        <Tooltip title={"Die Lizenz ist gültig"}>
                          <FontAwesomeIcon
                            size="1x"
                            color={SUCCESS.LIGHT}
                            icon={[ICON_STYLE, "circle-check"]}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title={"Die Lizenz ist ungültig"}>
                          <FontAwesomeIcon
                            size="1x"
                            color={ERROR.LIGHT}
                            icon={[ICON_STYLE, "circle-xmark"]}
                          />
                        </Tooltip>
                      )}
                    </TableCell> */}
                    <TableCell align="center">
                      <LicenceTypChip data={install} />
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {install?.shopVersion ?? `n/a`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 14,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {install?.phpVersion ?? `n/a`}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography
                        style={{
                          color: "#EEE",
                          fontSize: 12,
                          fontFamily: "Roboto",
                          fontWeight: "300",
                        }}
                      >
                        {moment(install?.last.toDate()).format("DD.MM.YYYY") ??
                          `n/a`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default InstallView;
