import { FormGroup, Switch } from "@material-ui/core";
import { FormControlLabel } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

interface TimeframeSelectorProps {
  onDateChanged: (value: Date[]) => void;
}

const TimeframeSelector: React.FC<TimeframeSelectorProps> = ({
  onDateChanged,
}) => {
  const [startDate, setStartDate] = useState<Date>(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  });

  const [endDate, setEndDate] = useState<Date>(() => {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    return date;
  });

  const [startDateError, setStartDateError] = useState<string>("");
  const [endDateError, setEndDateError] = useState<string>("");
  const [activeChip, setActiveChip] = useState<number | null>(1);

  const formatDate = (date: Date): string => {
    return new Intl.DateTimeFormat("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    }).format(date);
  };

  const formatDateForInput = (date: Date): string => {
    return moment(date).format("YYYY-MM-DD");
  };

  const isValidDate = (date: Date): boolean => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const handleQuickSelect = (daysBack: number, chipIndex: number): void => {
    const start = new Date();
    const end = new Date();

    start.setDate(end.getDate() - daysBack);
    start.setHours(0, 0, 0, 0);

    if (chipIndex == 0) end.setDate(end.getDate() - 1);
    end.setHours(23, 59, 59, 999);

    setStartDate(start);
    setEndDate(end);
    setStartDateInput(formatDateForInput(start));
    setEndDateInput(formatDateForInput(end));
    setStartDateError("");
    setEndDateError("");
    setActiveChip(chipIndex);
    onDateChanged([start, end]);

    setIsShowingRange(chipIndex != 0);
  };

  useEffect(() => {
    handleQuickSelect(30, 1);
  }, []);

  const [startDateInput, setStartDateInput] = useState<string>("");
  const [endDateInput, setEndDateInput] = useState<string>("");
  const [isShowingRange, setIsShowingRange] = useState<boolean>(true);

  const handleToggleSwitch = () => {
    setIsShowingRange(!isShowingRange);
  };

  const handleDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    isStart: boolean
  ) => {
    const value = event.target.value;
    if (isStart) {
      setStartDateInput(value);
    } else {
      setEndDateInput(value);
    }
  };

  const handleDateBlur = (
    event: React.FocusEvent<HTMLInputElement>,
    isStart: boolean
  ) => {
    const value = event.target.value;

    // Ensure the input ins in "YYYY-MM-DD" Format before parsing
    if (!/^\d{4}-\d{2}-\d{2}$/.test(value)) {
      isStart
        ? setStartDateError("Invalid date format")
        : setEndDateError("Invalid date format");
      return;
    }

    const [year, month, day] = value.split("-").map(Number);
    const newDate = new Date(year, month - 1, day);

    if (isValidDate(newDate)) {
      if (isStart) {
        newDate.setHours(0, 0, 0, 0);
        if (newDate > endDate && isShowingRange) {
          setStartDateError("Start date cannot be after end date");
          return;
        }
        setStartDateError("");
        setStartDate(newDate);
        onDateChanged([newDate, endDate]);
        if (!isShowingRange) {
          let tempDate = new Date(newDate);
          tempDate.setHours(23, 59, 59, 999);
          setEndDateInput(value);
          setEndDateError("");
          setEndDate(tempDate);
          onDateChanged([newDate, tempDate]);
        } else {
          onDateChanged([newDate, endDate]);
        }
      } else {
        newDate.setHours(23, 59, 59, 999);
        if (newDate < startDate) {
          setEndDateError("End date cannot be before start date");
          return;
        }
        setEndDateError("");
        setEndDate(newDate);
        onDateChanged([startDate, newDate]);
      }
      if (activeChip != 0) {
        setActiveChip(null);
      }
    } else {
      isStart
        ? setStartDateError("Invalid date")
        : setEndDateError("Invalid date");
    }
  };

  const quickSelectOptions = [
    { label: "Today", days: 1 },
    { label: "1 Month", days: 30 },
    { label: "3 Month", days: 90 },
    { label: "6 Months", days: 180 },
    { label: "1 year", days: 365 },
  ];

  return (
    <div className="w-full max-w-6xl mx-auto p-6">
      <div className="bg-gray-900 bg-opacity-50 p-6 rounded-xl">
        <div className="bg-gray-800 rounded-lg p-6 shadow-lg mb-6 border border-gray-700">
          <div className="flex flex-col md:flex-column justify-between gap-4">
            <div className="flex-1">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Start Date
              </label>
              <div className="relative">
                <input
                  type="date"
                  value={startDateInput || formatDateForInput(startDate)}
                  onChange={(e) => handleDateChange(e, true)}
                  onBlur={(e) => handleDateBlur(e, true)}
                  className={`w-full pl-10 pr-4 py-2 bg-gray-700 text-gray-100 border rounded-md focus:ring-2 focus:outline-none transition-colors ${
                    startDateError
                      ? "border-red-500 focus:ring-red-900"
                      : "border-gray-600 focus:ring-blue-800 focus:border-blue-700"
                  }`}
                />

                {startDateError && (
                  <p className="text-red-400 text-xs mt-1">{startDateError}</p>
                )}
                <FormGroup className="p-2">
                  <FormControlLabel
                    label="Daterange"
                    control={
                      <Switch
                        color="primary"
                        checked={isShowingRange}
                        onChange={handleToggleSwitch}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                  />
                </FormGroup>
              </div>
            </div>
            {isShowingRange && (
              <div className="flex-1">
                <label className="block text-sm font-medium text-gray-300 mb-2">
                  End Date
                </label>
                <div className="relative">
                  <input
                    type="date"
                    value={endDateInput || formatDateForInput(endDate)}
                    onChange={(e) => handleDateChange(e, false)}
                    onBlur={(e) => handleDateBlur(e, false)}
                    className={`w-full pl-10 pr-4 py-2 bg-gray-700 text-gray-100 border rounded-md focus:ring-2 focus:outline-none transition-colors ${
                      startDateError
                        ? "border-red-500 focus:ring-red-900"
                        : "border-gray-600 focus:ring-blue-800 focus:border-blue-700"
                    }`}
                  />
                  {endDateError && (
                    <p className="text-red-400 text-xs mt-1">{endDateError}</p>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-wrap gap-3 justify-center mb-6">
          {quickSelectOptions.map((option, index) => (
            <button
              key={option.label}
              onClick={() => handleQuickSelect(option.days, index)}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-all ${
                activeChip === index
                  ? "bg-blue-600 text-white shadow-lg"
                  : "bg-gray-700 text-gray-300 hover:bg-gray-600 border border-gray-600"
              }`}
            >
              {option.label}
            </button>
          ))}
        </div>

        <div className="text-center text-gray-300">
          <p className="text-sm font-medium mb-1">Selected Range</p>
          <p className="text-lg font-semibold">
            {formatDate(startDate)} - {formatDate(endDate)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TimeframeSelector;
