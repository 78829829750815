import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import ClickTimeResults from "./ClickTimeResults";
import { useDocument, useSetDocument } from "../../../firestore/hooks";
import LoadingWrapper from "../../../components/LoadingWrapper";
import Duplicates from "./Duplicates";
import { ShortURL } from "../../../firestore/models";
import ReactTimeago from "react-timeago";
import { COLL_SHORTURL, DB, functions } from "../../../firebase";
import { useSnackbar } from "notistack";
import Confirm from "../../../components/Confirm";
import ShortURLEdit from "../edit";
import PageContainer from "../../../components/PageContainer";
import { ICON_STYLE } from "../../../utils/constants";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  Title,
  LineElement,
  PointElement,
} from "chart.js";
import { Refresh } from "@material-ui/icons";

type DetailsParams = {
  keyword: string;
};

const DetailsNew = () => {
  const navigate = useNavigate();
  const { keyword } = useParams<DetailsParams>();
  const shortURL = useDocument<ShortURL>(COLL_SHORTURL, keyword);
  const { enqueueSnackbar } = useSnackbar();
  //   const refClicksFrom = useRef<HTMLInputElement>();
  //   const refClicksTo = useRef<HTMLInputElement>();
  //   const [clickTimes, setClickTimes] = useState<Date[] | null>(null);
  const [fromDate, setFromDate] = useState<Date>(
    moment().subtract(4, "weeks").weekday(1).toDate()
  );
  const [toDate, setToDate] = useState<Date>(moment().toDate());

  const [from, setFrom] = React.useState<Date>(
    moment().subtract(4, "weeks").weekday(1).toDate()
  );
  const [to, setTo] = React.useState<Date>(moment().toDate());

  const [clickData, setClickData] = useState<Record<string, any>>({});
  const [edit, setEdit] = useState(false);
  const updateShortURL = useSetDocument<ShortURL>(COLL_SHORTURL);
  const [clickList, setClickList] = useState<Array<Date>>([]);

  //   const handleClickTime = () => {
  //     if (refClicksFrom.current && refClicksTo.current) {
  //       const from = moment(`${refClicksFrom.current.value} 00:00:00`).toDate();
  //       const to = moment(`${refClicksTo.current.value} 23:59:59`).toDate();
  //       setFromDate(from);
  //       setToDate(to);
  //       setClickTimes([from, to]);
  //     }
  //   };

  const handleFromChange = (fromNew: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new Date(fromNew.target.value).toISOString();
    } catch {
      return;
    }
    setFrom(new Date(fromNew.target.value));
  };
  const handleToChange = (toNew: React.ChangeEvent<HTMLInputElement>) => {
    try {
      new Date(toNew.target.value).toISOString();
    } catch {
      return;
    }
    setTo(new Date(toNew.target.value));
  };

  const submitDates = () => {
    setFromDate(from);
    setToDate(to);
  };

  const handleAddAlias = () => {
    const alias = prompt("Alias eingeben:");
    if (alias && alias.trim()) {
      functions()
        .httpsCallable("resource")({
          resource: "shorturl",
          action: "addAlias",
          data: {
            id: keyword,
            alias: alias,
            force: true,
          },
        })
        .then((res) => {
          if (res.data === false) {
            return enqueueSnackbar("Error: Something went wrong", {
              variant: "error",
            });
          }
          if (
            res.data.status &&
            res.data.status === "error" &&
            res.data.message
          ) {
            return enqueueSnackbar("Error: " + res.data.message, {
              variant: "error",
            });
          }
        })
        .catch((err) => enqueueSnackbar("Error: " + err, { variant: "error" }));
    }
  };

  const handleRemoveAlias = (alias: string) => () => {
    if (shortURL?.alias) {
      const newAliases = [...shortURL.alias].filter((a) => a !== alias);
      updateShortURL({ alias: newAliases }, keyword)
        .then(() =>
          enqueueSnackbar(`Alias '${alias}' successfully removed.`, {
            variant: "success",
          })
        )
        .catch((e) => enqueueSnackbar("Error: " + e, { variant: "error" }));
    }
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Aufrufe",
      },
    },
    scales: {
      y: {
        min: 0,
      },
    },
  };
  ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
  );

  useEffect(() => {
    const preparedDateMap = (): Record<string, any> => {
      let currentDate = fromDate;
      const endDate = toDate;
      const dateMap: Record<string, any> = {};
      while (currentDate < endDate) {
        dateMap[moment(currentDate).format("DD.MM.YYYY")] = 0;
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
      }
      return dateMap;
    };

    const reducedData: Record<string, any> = clickList
      .map((d) => {
        return moment(d).format("DD.MM.YYYY");
      })
      .reduce<Record<string, number>>((prev, curr) => {
        if (prev?.[curr]) {
          return { ...prev, [curr]: prev[curr] + 1 };
        } else {
          return { ...prev, [curr]: 1 };
        }
      }, {});

    const orderedData = Object.entries(reducedData)
      .sort((a, b) => {
        if (new Date(a[0]) < new Date(b[0])) {
          return 1;
        } else if (new Date(a[0]) > new Date(b[0])) {
          return -1;
        } else {
          return 0;
        }
      })
      .reduce<Record<string, any>>((acc, curr) => {
        const key = curr[0];
        const value = curr[1];
        return { ...acc, [key]: value };
      }, preparedDateMap());
    setClickData(orderedData);
  }, [clickList]);

  const data = {
    labels: Object.keys(clickData),
    datasets: [
      {
        label: "Aufrufe",
        data: Object.entries(clickData).map(([, v]) => {
          return v;
        }),
        backgroundColor: `rgba(52, 137, 235)`,
        borderColor: `#4f9fb3`,
      },
    ],
  };

  useEffect(() => {
    DB()
      .collection(`shorturl/${keyword}/sllog`)
      .where("clickTime", ">", fromDate)
      .where("clickTime", "<", toDate)
      .get()
      .then((docs) => {
        const dateArray: Array<Date> = [];
        docs.forEach((doc) => {
          if (doc.data().clickTime) {
            dateArray.push(doc.data().clickTime?.toDate());
          }
        });
        setClickList(dateArray);
      });
  }, [fromDate, toDate]);

  return (
    <LoadingWrapper loading={shortURL === undefined}>
      {shortURL && (
        <PageContainer
          title={shortURL.title}
          onClose={() => navigate("/shorturl")}
          onEdit={() => setEdit(true)}
        >
          <div className="float-right"></div>

          <Grid container style={{ marginTop: 30, marginLeft: 10 }} spacing={2}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {`Keyword:`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "white",
                    }}
                  >
                    {shortURL.keyword}
                  </Typography>
                  <Link
                    style={{ marginLeft: 5 }}
                    href={`https://ws-url.de/${shortURL.keyword}`}
                    target={"_blank"}
                  >
                    <FontAwesomeIcon icon={[ICON_STYLE, "external-link"]} />
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {`URL:`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={10}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "white",
                    }}
                  >
                    {shortURL.url}
                  </Typography>
                  <Link
                    href={shortURL.url}
                    target={"_blank"}
                    style={{ marginLeft: 5 }}
                  >
                    <FontAwesomeIcon icon={[ICON_STYLE, "external-link"]} />
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {`Klicks:`}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "white",
                    }}
                  >
                    {shortURL.clicks}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {`IP:`}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "white",
                    }}
                  >
                    {shortURL.ip}
                  </Typography>
                </Grid>
                {shortURL.category && (
                  <>
                    <Grid item xs={2}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        {`Kategorie:`}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {typeof shortURL.category === "string" ? (
                        <Chip label={shortURL.category} />
                      ) : (
                        shortURL.category.map((category) => (
                          <Chip className="mx-1" label={category} />
                        ))
                      )}
                    </Grid>
                  </>
                )}
                {shortURL.alias && (
                  <>
                    <Grid item xs={2}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          color: "white",
                        }}
                      >
                        {`Alias:`}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {shortURL.alias.map((alias) => (
                        <Chip className="mx-1" label={alias} />
                      ))}
                    </Grid>
                  </>
                )}

                {/* <>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {`Alias:`}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={10}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {shortURL.alias && shortURL.alias.length ? (
                      shortURL.alias.map((alias) => (
                        <Confirm
                          title="Remove Alias"
                          content={`Remove Alias '${alias}' from '${keyword}'?`}
                          onConfirm={handleRemoveAlias(alias)}
                        >
                          {(confirm) => (
                            <Chip
                              onDelete={() => confirm(true)}
                              className="mx-1"
                              label={alias}
                            />
                          )}
                        </Confirm>
                      ))
                    ) : (
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "lighter",
                          color: "white",
                        }}
                      >
                        {`n/a`}
                      </Typography>
                    )}
                    <Button
                      style={{ marginLeft: 5 }}
                      variant="outlined"
                      onClick={handleAddAlias}
                    >
                      <FontAwesomeIcon icon={[ICON_STYLE, "plus"]} />
                    </Button>
                  </Grid>
                </> */}

                {/* <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {`Paramaters:`}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  {shortURL.params ? (
                    Object.keys(shortURL?.params).map((key) => (
                      <>
                        <code>{key}</code> <b>=&gt;</b>{" "}
                        <code>{shortURL.params && shortURL.params[key]}</code>
                      </>
                    ))
                  ) : (
                    <Typography
                      style={{
                        fontSize: 14,
                        fontWeight: "lighter",
                        color: "white",
                      }}
                    >
                      {`n/a`}
                    </Typography>
                  )}
                </Grid> */}
                <Grid item xs={2}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    {`Zuletzt geändert:`}
                  </Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    style={{
                      fontSize: 14,
                      fontWeight: "lighter",
                      color: "white",
                    }}
                  >
                    <ReactTimeago date={shortURL.createdAt.toDate()} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={8}
              style={{
                display: "flex",
                marginTop: 20,
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ width: "100%", textAlign: "left" }}
                variant={"h4"}
              >
                Statistik
              </Typography>
              {/* <Box style={{ textAlign: "center", margin: "10px" }}>
                <TextField
                  type="date"
                  label="From"
                  name="clicksFrom"
                  inputRef={refClicksFrom}
                  fullWidth
                  defaultValue={moment().add(-7, "days").format("YYYY-MM-DD")}
                />
              </Box>
              <Box style={{ textAlign: "center", margin: "10px" }}>
                <TextField
                  type="date"
                  label="To"
                  name="clicksTo"
                  inputRef={refClicksTo}
                  fullWidth
                  defaultValue={moment().format("YYYY-MM-DD")}
                />
              </Box>
              <Box style={{ textAlign: "center", margin: "10px" }}>
                <Button
                  onClick={handleClickTime}
                  variant={"outlined"}
                  color={"primary"}
                >
                  send
                </Button>
              </Box> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Von
                <input
                  type={`date`}
                  value={moment(from || new Date()).format("YYYY-MM-DD")}
                  onChange={handleFromChange}
                  style={{
                    padding: 3,
                    borderRadius: 10,
                    marginLeft: 5,
                    marginRight: 10,
                  }}
                />
                Bis
                <input
                  type={`date`}
                  value={moment(to || new Date()).format("YYYY-MM-DD")}
                  onChange={handleToChange}
                  style={{
                    padding: 3,
                    borderRadius: 10,
                    marginLeft: 5,
                    marginRight: 10,
                  }}
                />
                <IconButton onClick={submitDates}>
                  <Refresh fontSize={"small"} />
                </IconButton>
              </div>
              <div
                style={{
                  margin: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignContent: "center",
                  alignItems: "center",
                  // backgroundColor: "#eee",
                  //   borderWidth: 2,
                  //   borderColor: "white",
                  //   borderStyle: "solid",
                  minWidth: "90%",
                  flexGrow: 2,
                  padding: 10,
                  borderRadius: 15,
                }}
              >
                <Line data={data} options={options} />
              </div>
              <Typography
                style={{
                  marginTop: 10,
                  fontSize: 16,
                  fontWeight: "lighter",
                  color: "white",
                }}
              >
                {`Es gab insgesamt ${clickList.length} Aufrufe im ausgewählten Zeitraum `}
              </Typography>
            </Grid>

            <Duplicates shorturl={shortURL} />
          </Grid>
        </PageContainer>
      )}

      {/* {shortURL && clickTimes !== null && (
        <Grid item xs={12}>
          <ClickTimeResults
            from={clickTimes[0]}
            to={clickTimes[1]}
            keyword={shortURL.keyword}
          />
        </Grid>
      )} */}

      {edit && shortURL && (
        <ShortURLEdit
          open={edit}
          onClose={() => setEdit(false)}
          shorturl={shortURL}
        />
      )}
    </LoadingWrapper>
  );
};

export default DetailsNew;
