import React, { useCallback, useEffect, useState } from "react";
import getAbocloud from "../../../firebase/abocloud";
import firebase from "firebase/compat/app";
import TrendCard from "./TrendCard";
import TimeframeSelector from "../../../components/TimeframeSelector/index";
import AbocloudLineGraph from "../components/LineGraph/index";

const Dashboard = () => {
  const [statistics, setStatistics] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();
  const [events, setEvents] =
    useState<Array<firebase.firestore.QueryDocumentSnapshot>>();
  const [refreshingStats, setRefreshingStats] = useState<boolean>(false);
  const now = new Date();
  const [startEndDates, setStartEndDates] = useState<Date[]>([
    new Date("2022-01-01"),
    now,
  ]);
  const [dataArrays, setDataArrays] = useState<ReturnType<
    typeof getDataArray
  > | null>(null);
  const abocloudDatabase = getAbocloud();
  const aboCloudMerchantId = "4qVOrruYOZUFktHnwRpo";

  const getCachedData = () => {
    const cachedData = localStorage.getItem("statisticsCache");
    const cachedTimestamp = localStorage.getItem("statisticsCacheDate");
    const today = new Date().toISOString().split("T")[0];

    if (cachedData && cachedTimestamp === today && cachedData.length > 10) {
      return JSON.parse(cachedData);
    }
    return null;
  };

  const createDataArray = useCallback(
    (type: "costumers_new" | "costumers_all" | "profit") => {
      if (!statistics) return [];

      return Object.entries(statistics)
        .map(([k, v]) => {
          const data = v.data();
          const tempTimestamp = data?.["timestamp"] || null;
          const dateTimestemp = tempTimestamp?.toDate();

          const { timestamp, ...cleanedData } = data;
          return {
            createdAt: dateTimestemp || null,
            total: cleanedData?.[type]?.all || 0,
            free: cleanedData?.[type]?.free || 0,
            business: cleanedData?.[type]?.business || 0,
            pro: cleanedData?.[type]?.pro || 0,
          };
        })
        .sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return a.createdAt > b.createdAt ? 1 : -1;
          }
          return 0;
        });
    },
    [statistics]
  );

  const createDataEvents = useCallback(
    (type: "abo_canceled") => {
      if (!events) return [];

      return Object.entries(events).map(([k, v]) => {
        const data = v.data();

        const dateTimestemp = data.createTime.toDate();

        return {
          createdAt: dateTimestemp,
          type: data.type || "",
          aboType: data.metaData?.oldAbo || "",
        };
      });
    },
    [events]
  );

  const getDataArray = useCallback(() => {
    const cachedData = getCachedData();
    const cachedTimestamp = localStorage.getItem("statisticsCacheDate");
    const today = new Date().toISOString().split("T")[0];
    if (cachedData && cachedTimestamp === today) {
      return cachedData;
    }

    const data = {
      costumersAll: createDataArray("costumers_all"),
      costumersNew: createDataArray("costumers_new"),
      profit: createDataArray("profit"),
    };

    localStorage.setItem("statisticsCache", JSON.stringify(data));
    localStorage.setItem(
      "statisticsCacheDate",
      new Date().toISOString().split("T")[0]
    );

    return data;
  }, [createDataArray]);

  useEffect(() => {
    abocloudDatabase.db
      .collection("merchants")
      .doc(aboCloudMerchantId)
      .collection("events")
      .get()
      .then((snap) => {
        setEvents(snap.docs ?? []);
      });

    if (getCachedData()) {
      setStatistics([]);
      return;
    }
    abocloudDatabase.db
      .collection("statistics")
      .limit(1000)
      .get()
      .then((snap) => {
        setStatistics(snap.docs ?? []);
        setRefreshingStats(false);
      });
  }, []);

  useEffect(() => {
    if (statistics && events) {
      setDataArrays({
        ...getDataArray(),
        events: createDataEvents("abo_canceled"),
      });
    }
  }, [statistics, getDataArray, events, createDataEvents]);

  const handleDateChange = (value: Date[]) => {
    setStartEndDates([...value]);
  };

  if (!dataArrays) return <div>Loading...</div>;

  return (
    <>
      <div className="flex flex-col p-6">
        <div className="flex flex-row p-6 justify-between w-full">
          <div
            className="flex-1"
            style={{
              minWidth: 600,
              maxWidth: 1000,
            }}
          >
            <TimeframeSelector onDateChanged={handleDateChange} />
          </div>
          <div className="text-center flex justify-evenly my-5">
            {refreshingStats && <div className="text-sm">Loading</div>}
          </div>
          <div className="flex flex-col px-3 py-6">
            <TrendCard
              title="Customer Average"
              data={dataArrays.costumersAll}
              startEndDates={startEndDates}
              aggregationType="average"
            />
            <TrendCard
              title="New Customers"
              data={dataArrays.costumersNew}
              startEndDates={startEndDates}
              aggregationType="sum"
            />
          </div>
          <div className="flex flex-col justify-between px-3 py-6">
            <TrendCard
              title="Cancelations"
              data={dataArrays.costumersAll}
              startEndDates={startEndDates}
              aggregationType="churn"
              secondaryData={dataArrays.events}
            />
            <TrendCard
              title="Profit"
              data={dataArrays.profit}
              startEndDates={startEndDates}
              aggregationType="profit"
              secondaryData={dataArrays.costumersAll}
            />
          </div>
        </div>
        <div className="flex flex row justify-center">
          {startEndDates[1].getTime() - startEndDates[0].getTime() >
            24 * 60 * 61 * 1000 && (
            <AbocloudLineGraph
              startEndDates={[startEndDates[0], startEndDates[1]]}
              checkouts={dataArrays.costumersAll}
              events={dataArrays.events}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
