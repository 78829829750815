import React, { useRef, useEffect, useState } from "react";
import {
  Chart,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  ChartOptions,
  ChartData,
} from "chart.js";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";

Chart.register(LineElement, PointElement, LinearScale, CategoryScale);

interface Checkout {
  createdAt: string;
  total: number;
  business: number;
  pro: number;
  type?: string;
}

interface LineGraphProps {
  startEndDates: [Date, Date];
  checkouts: Checkout[];
  events: Checkout[];
}

// Generate date labels between start and end dates
const generateDateLabels = (start: Date, end: Date): string[] => {
  const labels: string[] = [];
  let current = moment(start);
  while (current.isBefore(end, "day")) {
    if (end.getTime() - start.getTime() > 365 * 24 * 60 * 60 * 1000) {
      labels.push(current.format("YYYY-MM-DD"));
    } else {
      labels.push(current.format("DD.MM"));
    }
    current.add(1, "day");
  }
  return labels;
};

const processData = (
  checkouts: Checkout[],
  startDate: Date,
  endDate: Date,
  type: string
): Record<string, number> => {
  return checkouts.reduce<Record<string, number>>((acc, checkout) => {
    const createdAtDate = new Date(checkout.createdAt);
    if (createdAtDate >= startDate && createdAtDate < endDate) {
      if (createdAtDate >= new Date("2025-01-29")) {
        createdAtDate.setDate(createdAtDate.getDate() - 1);
      }
      //Different Date Formats since chart would scramble them if timespan is larger than a year
      let internalDate;
      if (endDate.getTime() - startDate.getTime() > 365 * 24 * 60 * 60 * 1000) {
        internalDate = moment(createdAtDate).format("YYYY-MM-DD");
      } else {
        internalDate = moment(createdAtDate).format("DD.MM");
      }

      switch (type) {
        case "total":
          acc[internalDate] = (acc[internalDate] || 0) + checkout.total;
          break;
        case "business":
          acc[internalDate] = (acc[internalDate] || 0) + checkout.business;
          break;
        case "pro":
          acc[internalDate] = (acc[internalDate] || 0) + checkout.pro;
          break;
        case "cancelations":
          console.log("checkout", checkout);
          acc[internalDate] =
            (acc[internalDate] || 0) + (checkout.type || "") === "abo_canceled"
              ? 0
              : 1;
          console.log("checkout v", acc[internalDate]);
          break;
        default:
          break;
      }
    }
    return acc;
  }, {});
};

const AbocloudLineGraph: React.FC<LineGraphProps> = ({
  startEndDates,
  checkouts,
  events,
}) => {
  const chartRef = useRef<Chart | null>(null);

  const [isShowingAll, setIsShowingAll] = useState<boolean>(true);
  const [isShowingBusiness, setIsShowingBusiness] = useState<boolean>(true);
  const [isShowingPro, setIsShowingPro] = useState<boolean>(true);
  const [isShowingCancelations, setIsShowingCancelations] =
    useState<boolean>(true);

  useEffect(() => {
    return () => {
      // Destroy the chart instance when component unmounts
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, []);

  const handleToggleSwitch = (type: string) => {
    switch (type) {
      case "all":
        setIsShowingAll(!isShowingAll);
        break;
      case "business":
        setIsShowingBusiness(!isShowingBusiness);
        break;
      case "pro":
        setIsShowingPro(!isShowingPro);
        break;
      case "cancelations":
        setIsShowingCancelations(!isShowingCancelations);
        break;
      default:
        break;
    }
  };

  const labels = generateDateLabels(startEndDates[0], startEndDates[1]);
  const totalDataForRange = processData(
    checkouts,
    startEndDates[0],
    startEndDates[1],
    "total"
  );
  const businessDataForRange = processData(
    checkouts,
    startEndDates[0],
    startEndDates[1],
    "business"
  );
  const proDataForRange = processData(
    checkouts,
    startEndDates[0],
    startEndDates[1],
    "pro"
  );
  const eventDataForRange = processData(
    events,
    startEndDates[0],
    startEndDates[1],
    "cancelations"
  );

  const allData = [];

  if (isShowingAll) {
    allData.push({
      label: "Customer",
      fill: true,
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgb(40, 99, 228)",
      data: labels.map((label) => totalDataForRange[label] || 0),
    });
  }
  if (isShowingBusiness) {
    allData.push({
      label: "Customer",
      fill: true,
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgb(13, 134, 66)",
      data: labels.map((label) => businessDataForRange[label] || 0),
    });
  }
  if (isShowingPro) {
    allData.push({
      label: "Customer",
      fill: true,
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgb(98, 19, 148)",
      data: labels.map((label) => proDataForRange[label] || 0),
    });
  }
  if (isShowingCancelations) {
    allData.push({
      label: "Customer",
      fill: true,
      backgroundColor: "rgba(75, 192, 192, 0.2)",
      borderColor: "rgb(166, 21, 21)",
      data: labels.map((label) => eventDataForRange[label] || 0),
    });
  }

  const data: ChartData<"line"> = {
    labels: labels,
    datasets: allData,
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      line: {
        fill: false,
      },
    },
    scales: {
      y: {
        ticks: {
          color: "white",
          stepSize: 1,
        },
        grid: {
          color: "rgba(255, 255, 255, 0.1)",
        },
      },
      x: {
        ticks: {
          color: "white",
        },
      },
    },
  };

  return (
    <div style={{ height: "400px", width: "100%", paddingLeft: "2%" }}>
      <div style={{ height: "400px", width: "100%" }}>
        <Line data={data} options={options} />
      </div>
      <div className="flex flex-row">
        <FormGroup className="p-2">
          <FormControlLabel
            label="All"
            control={
              <Switch
                color="primary"
                checked={isShowingAll}
                onChange={() => handleToggleSwitch("all")}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
        <FormGroup className="p-2">
          <FormControlLabel
            label="Business"
            control={
              <Switch
                color="primary"
                checked={isShowingBusiness}
                onChange={() => handleToggleSwitch("business")}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
        <FormGroup className="p-2">
          <FormControlLabel
            label="Pro"
            control={
              <Switch
                color="primary"
                checked={isShowingPro}
                onChange={() => handleToggleSwitch("pro")}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
        <FormGroup className="p-2">
          <FormControlLabel
            label="Cancelations"
            control={
              <Switch
                color="primary"
                checked={isShowingCancelations}
                onChange={() => handleToggleSwitch("cancelations")}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default AbocloudLineGraph;
