import React from "react";
import { DeepMap, FieldError } from "react-hook-form";
import { Box, TextField } from "@material-ui/core";
import { ShortURL } from "../../firestore/models/ShortURL";

type Props = {
  shorturl?: ShortURL;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  errors: DeepMap<Record<string, any>, FieldError>;
  register: Function;
};

const ShortURLForm = (props: Props) => {
  return (
    <form onSubmit={props.onSubmit} noValidate>
      <Box>
        <TextField
          label="Keyword"
          error={!!props.errors.keyword}
          required
          margin="normal"
          InputProps={{ readOnly: !!props.shorturl?.keyword }}
          defaultValue={props.shorturl ? props.shorturl.keyword : ""}
          helperText={props.errors.keyword?.message ?? null}
          fullWidth
          inputProps={props.register("keyword")}
        />
      </Box>
      <Box>
        <TextField
          label="Title"
          error={!!props.errors.title}
          required
          margin="normal"
          defaultValue={props.shorturl ? props.shorturl.title : ""}
          helperText={props.errors.title?.message ?? null}
          fullWidth
          inputProps={props.register("title")}
        />
      </Box>
      <Box>
        <TextField
          label="URL"
          error={!!props.errors.url}
          required
          margin="normal"
          defaultValue={props.shorturl ? props.shorturl.url : ""}
          helperText={props.errors.url?.message ?? null}
          fullWidth
          inputProps={props.register("url")}
        />
      </Box>
      <Box>
        <TextField
          label="Category"
          error={!!props.errors.category}
          margin="normal"
          defaultValue={props.shorturl ? props.shorturl.category : ""}
          helperText={props.errors.category?.message ?? null}
          fullWidth
          inputProps={props.register("category")}
        />
      </Box>
      <Box>
        <TextField
          label="Alias"
          error={!!props.errors.alias}
          margin="normal"
          defaultValue={props.shorturl ? props.shorturl.alias : ""}
          helperText={props.errors.alias?.message ?? null}
          fullWidth
          inputProps={props.register("alias")}
        />
      </Box>
    </form>
  );
};

export default ShortURLForm;
