import React from "react";
import { Alert } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactTimeago from "react-timeago";
import PageContainer from "../../../../../components/PageContainer";
import firebase from "firebase/compat/app";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

type TabSessionsProps = {
  sessionDocs?: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;
};

const TabSessions = ({ sessionDocs }: TabSessionsProps) => {
  return (
    <PageContainer
      bgColor="bg-gray-800"
      heading={3}
      title="Sessions"
      loading={sessionDocs === undefined}
    >
      {sessionDocs?.empty ? (
        <Alert severity={"warning"}>No active Sessions</Alert>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "stretch",
            alignContent: "stretch",
            flexWrap: "wrap",
            gap: 10,
          }}
        >
          {sessionDocs?.docs.map((sessionDoc) => {
            const session = sessionDoc.data();
            return (
              <Card
                style={{
                  borderRadius: 10,
                  width: 450,
                  minWidth: 450,
                  flex: "1 1 450",
                }}
              >
                <CardHeader title={`User-Session`} subheader={sessionDoc.id} />
                <CardContent>
                  <Grid container spacing={1} alignItems={"center"}>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="Erstellt vor"
                        icon={["fas", "clock"]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "300",
                          fontFamily: "Roboto",
                        }}
                      >
                        <ReactTimeago date={session.created.toDate()} />
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="App-Version"
                        icon={["fas", "microchip"]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "300",
                          fontFamily: "Roboto",
                        }}
                      >{`${session?.appVersion || "unknown"}`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="Plattform"
                        icon={["fas", "desktop"]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "300",
                          fontFamily: "Roboto",
                        }}
                      >{`${session?.platform || "unknown"}`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="Plattform-Version"
                        icon={["fas", "window"]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "300",
                          fontFamily: "Roboto",
                        }}
                      >{`${session?.platformVersion || "unknown"}`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="Screen-Größe"
                        icon={["fas", "expand-wide"]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "300",
                          fontFamily: "Roboto",
                        }}
                      >{`${session?.dimensions || "unknown"}`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="Sprache"
                        icon={["fas", "language"]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "300",
                          fontFamily: "Roboto",
                        }}
                      >{`${
                        session?.preferedLanguage || "unknown"
                      }`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="Cookies erlaubt"
                        icon={["fas", "cookie"]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "300",
                          fontFamily: "Roboto",
                        }}
                      >{`${
                        session?.cookiesEnabled === true ? "ja" : "nein"
                      }`}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="Push-Benachrichtigungen möglich"
                        icon={["fas", "comment"]}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      {session.expoToken ? (
                        <FontAwesomeIcon
                          color="green"
                          icon={["fas", "check-circle"]}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color="red"
                          icon={["fas", "times-circle"]}
                        />
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      <FontAwesomeIcon
                        title="User-Agent"
                        icon={["fas", "user-secret"]}
                      />
                    </Grid>
                    <Grid item xs={11}>
                      <Typography
                        style={{
                          fontSize: 14,
                          fontWeight: "300",
                          fontFamily: "Roboto",
                        }}
                      >{`${session?.userAgent || "unknown"}`}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <IconButton
                    title="Löschen"
                    onClick={() =>
                      window.confirm("Wirklich löschen?")
                        ? sessionDoc.ref.delete()
                        : null
                    }
                  >
                    <Delete style={{ color: "#691c1c" }} />
                  </IconButton>
                </CardActions>
              </Card>
            );
          })}
        </div>
        // <Table
        //   header={{
        //     id: { content: "ID" },
        //     platform: { content: "Platform" },
        //     vendor: { content: "Vendor" },
        //     push: { content: "Push" },
        //     created: { content: "Created" },
        //     actions: { content: "" },
        //   }}
        // >
        //   {sessionDocs?.docs.map((sessionDoc) => {
        //     const session = sessionDoc.data() as SessionType;
        //     return (
        //       session && (
        //         <tr>
        //           <td>
        //             <IDLabel truncate label={sessionDoc.id} />
        //           </td>
        //           <td>{session.platform}</td>
        //           <td>{session.vendor}</td>
        //           <td className="text-center">
        //             {session.expoToken ? (
        //               <FontAwesomeIcon
        //                 color="green"
        //                 icon={[ICON_STYLE, "check-circle"]}
        //               />
        //             ) : (
        //               <FontAwesomeIcon
        //                 color="red"
        //                 icon={[ICON_STYLE, "times-circle"]}
        //               />
        //             )}
        //           </td>
        //           <td className="text-right">
        //             <ReactTimeago date={session.created.toDate()} />
        //           </td>
        //           <td>
        //             <FontAwesomeIcon
        //               icon={[ICON_STYLE, "trash"]}
        //               color="red"
        //               className="cursor-pointer ml-1"
        //               onClick={() =>
        //                 window.confirm("Wirklich löschen?")
        //                   ? sessionDoc.ref.delete()
        //                   : null
        //               }
        //             />
        //           </td>
        //         </tr>
        //       )
        //     );
        //   })}
        // </Table>
      )}
    </PageContainer>
  );
  // return (
  //   <PageContainer
  //     bgColor="bg-gray-800"
  //     heading={3}
  //     title="Sessions"
  //     loading={sessionDocs === undefined}
  //   >
  //     {sessionDocs?.empty ? (
  //       <Alert severity={"warning"}>No active Sessions</Alert>
  //     ) : (
  //       <Table
  //         header={{
  //           id: { content: "ID" },
  //           platform: { content: "Platform" },
  //           vendor: { content: "Vendor" },
  //           push: { content: "Push" },
  //           created: { content: "Created" },
  //           actions: { content: "" },
  //         }}
  //       >
  //         {sessionDocs?.docs.map((sessionDoc) => {
  //           const session = sessionDoc.data() as SessionType;
  //           return (
  //             session && (
  //               <tr>
  //                 <td>
  //                   <IDLabel truncate label={sessionDoc.id} />
  //                 </td>
  //                 <td>{session.platform}</td>
  //                 <td>{session.vendor}</td>
  //                 <td className="text-center">
  //                   {session.expoToken ? (
  //                     <FontAwesomeIcon
  //                       color="green"
  //                       icon={[ICON_STYLE, "check-circle"]}
  //                     />
  //                   ) : (
  //                     <FontAwesomeIcon
  //                       color="red"
  //                       icon={[ICON_STYLE, "times-circle"]}
  //                     />
  //                   )}
  //                 </td>
  //                 <td className="text-right">
  //                   <ReactTimeago date={session.created.toDate()} />
  //                 </td>
  //                 <td>
  //                   <FontAwesomeIcon
  //                     icon={[ICON_STYLE, "trash"]}
  //                     color="red"
  //                     className="cursor-pointer ml-1"
  //                     onClick={() =>
  //                       window.confirm("Wirklich löschen?")
  //                         ? sessionDoc.ref.delete()
  //                         : null
  //                     }
  //                   />
  //                 </td>
  //               </tr>
  //             )
  //           );
  //         })}
  //       </Table>
  //     )}
  //   </PageContainer>
  // );
};

export default TabSessions;
