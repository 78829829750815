import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chip } from "@material-ui/core";
import React from "react";
import { ICON_STYLE } from "../../../../../../utils/constants";

export const LicenceChip = ({ data }: { data?: Record<string, any> }) => {
  let color = "green";
  let label = "Gültig";

  if (data?.pixelVersion === undefined) {
    color = "gray";
    label = "Keine Daten";
  } else if (!data?.hasLicense) {
    color = "red";
    label = "Ungültig";
  }

  return (
    <Chip
      style={{ backgroundColor: color, color: "#fff" }}
      size="small"
      icon={
        <FontAwesomeIcon
          size="xs"
          style={{ color: "#ffffff", height: `60%` }}
          icon={[ICON_STYLE, "key"]}
        />
      }
      label={label}
    />
  );
};

export const SubscriptionChip = ({ data }: { data?: Record<string, any> }) => {
  let color = "green";
  let label = "Aktiv";
  if (data?.pixelVersion === undefined) {
    color = "gray";
    label = "Keine Daten";
  } else if (!data?.hasSubscription) {
    color = "red";
    label = "Keine";
  }

  return (
    <Chip
      size="small"
      style={{ backgroundColor: color, color: "#fff" }}
      icon={
        <FontAwesomeIcon
          size="xs"
          style={{ color: "#ffffff", height: `60%` }}
          icon={[ICON_STYLE, "arrows-rotate"]}
        />
      }
      label={label}
    />
  );
};

export const LicenceTypChip = ({ data }: { data?: Record<string, any> }) => {
  let color = "green";
  let label = "Aktiv";
  if (data?.pixelVersion === undefined) {
    color = "gray";
    label = "Keine Daten";
  } else if (data?.licenseType === null) {
    color = "red";
    label = "Keine Lizenz";
  } else if (data?.licenseType === `test`) {
    color = "gray";
    label = "TEST";
  } else if (data?.licenseType === `free`) {
    color = "green";
    label = "FREE";
  } else if (data?.licenseType === `prod`) {
    color = "green";
    label = "PROD";
  } else if (data?.licenseType === `dev`) {
    color = "orange";
    label = "DEV";
  } else {
    color = "red";
    label = "Keine Lizenz";
  }

  return (
    <Chip
      size="small"
      style={{ backgroundColor: color, color: "#fff", borderRadius: 8 }}
      label={label}
    />
  );
};
