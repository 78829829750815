import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from "@material-ui/core";
import React from "react";
import { COMPONENTS } from "../../../../../constants/colors";
import { Skeleton } from "@material-ui/lab";
import useProfile from "../../../../../hooks/firestore/useProfile";
import useAuth from "../../../../../hooks/useAuth";
import { UserRole } from "../../../../../firestore/models";
import RandomPlugin from "../../plugin/Random";
const logo = require("../assets/logo.png");
type DashboardCardProps = {
  title: string;
  children: React.ReactNode;
  loading?: boolean;
  headerChip?: string;
  role?: UserRole;
  theme?: "default" | "plugin" | "webstollen" | "marketing" | "dashbar" | "abocloud";
};

const cardTheme = (
  theme?: "default" | "plugin" | "webstollen" | "marketing" | "dashbar" | "abocloud"
) => {
  switch (theme) {
    case "webstollen":
      return COMPONENTS.DASHBOARD.THEMES.COLOR_1;
    case "plugin":
      return COMPONENTS.DASHBOARD.THEMES.COLOR_2;
    case "marketing":
      return COMPONENTS.DASHBOARD.THEMES.COLOR_3;
    case "dashbar":
      return COMPONENTS.DASHBOARD.THEMES.COLOR_4;
    case "default":
    default:
      return COMPONENTS.DASHBOARD.THEMES.COLOR_1;
  }
};
const cardName = (
  theme?: "default" | "plugin" | "webstollen" | "marketing" | "dashbar" | "abocloud"
) => {
  switch (theme) {
    case "webstollen":
      return `WebStollen`;
    case "plugin":
      return `Plugin`;
    case "marketing":
      return `Marketing`;
    case "dashbar":
      return `dash.bar`;
    case "abocloud":
      return `abocloud`;
    case "default":
    default:
      return `WebStollen`;
  }
};

const DashboardCard = ({
  title,
  loading = false,
  headerChip,
  theme,
  role,
  children,
}: DashboardCardProps) => {
  const profile = useProfile();
  const auth = useAuth();
  const hasPermission = () => {
    if (role === undefined || role === null) return true;
    if (auth && profile && profile.roles) {
      return (
        profile.roles.includes(UserRole.Operational) ||
        profile.roles.includes(role)
      );
    } else {
      return false;
    }
  };

  if (loading === true) {
    return (
      <Skeleton
        height={`100%`}
        width={`100%`}
        style={{
          height: "100%",
          minHeight: 300,
          width: "100%",
          backgroundColor: COMPONENTS.DASHBOARD.CARD.BACKGROUND,
          borderRadius: 5,
        }}
      />
    );
  }
  if (hasPermission() === false) {
    return <RandomPlugin />;
    // return null;
    // return (
    //   <div
    //     style={{
    //       height: "100%",
    //       backgroundColor: COMPONENTS.DASHBOARD.CARD.BACKGROUND,
    //       borderRadius: 5,
    //       width: "100%",
    //       display: "flex",
    //       flexDirection: "row",
    //       justifyContent: "center",
    //       alignContent: "center",
    //       alignItems: "center",
    //       borderStyle: "solid",
    //       borderWidth: theme === "default" ? 0 : 2,
    //       borderColor: cardTheme(theme),
    //     }}
    //   >
    //     <img
    //       src={logo}
    //       width={150}
    //       style={{ width: 150, height: "100%", objectFit: "contain" }}
    //     />
    //   </div>
    // );
  }
  return (
    <Card
      style={{
        height: "100%",
        backgroundColor: COMPONENTS.DASHBOARD.CARD.BACKGROUND,
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: theme === "default" ? 0 : 2,
        borderColor: cardTheme(theme),
      }}
    >
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#FFF",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: `500`,
              }}
            >
              {`${title}`}
            </Typography>
            {headerChip !== undefined && (
              <Chip
                size="small"
                label={
                  <Typography
                    style={{
                      color: "#FFF",
                      fontSize: 10,
                      fontFamily: "Roboto",
                      fontWeight: `300`,
                    }}
                  >
                    {`${headerChip}`}
                  </Typography>
                }
              />
            )}
          </div>
        }
        subheader={
          <Typography
            style={{
              color: cardTheme(theme),
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: `300`,
            }}
          >
            {cardName(theme)}
          </Typography>
        }
        // action={
        //   headerChip !== undefined ? (
        //     <Chip
        //       size="small"
        //       label={
        //         <Typography
        //           style={{
        //             color: "#FFF",
        //             fontSize: 10,
        //             fontFamily: "Roboto",
        //             fontWeight: `300`,
        //           }}
        //         >
        //           {`${headerChip}`}
        //         </Typography>
        //       }
        //     />
        //   ) : null
        // }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default DashboardCard;
